import { Alert, background, Badge, Box, Button, Checkbox, Container, Flex, Grid, GridItem, Heading, HStack, Image, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Spacer, Spinner, Stack, Text, useColorMode, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as constants from "../constants"
import { useParams } from "react-router-dom"
import nftAbi from "../abis/packs.json"

import { ChevronDownIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import WalletConnectProvider from "@walletconnect/web3-provider";
const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Home() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { colorMode, toggleColorMode } = useColorMode()
    const toast = useToast()
    const [account, setAccount] = useState(null)
    const [provider, setProvider] = useState(null)
    const [signer, setSigner] = useState(null)
    const [amount, setAmount] = useState(1)
    const [price, setPrice] = useState(0)
    const [currentPhase, setCurrentPhase] = useState(0)
    const [maxSupply, setMaxSupply] = useState(0)
    const [minted, setMinted] = useState(0)
    const [maxPerWallet, setMaxPerWallet] = useState(0)
    const contract = useMemo(() => {
        if (provider) {
            return new ethers.Contract('0xA59a64AE3F0d92DDfB48209e869c5D342e9a7281', nftAbi, signer)
        }
    }, [provider, signer])

    const connect = useCallback(async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: "wallet_requestPermissions",
                    params: [{
                        eth_accounts: {}
                    }]
                }).then(() => window.ethereum.request({
                    method: 'eth_requestAccounts'
                }))
                setProvider(new ethers.providers.Web3Provider(window.ethereum))
                setSigner(new ethers.providers.Web3Provider(window.ethereum).getSigner())
                setAccount(await new ethers.providers.Web3Provider(window.ethereum).getSigner().getAddress())
            } catch (err) {
                toast({
                    title: "Error",
                    description: "Error connecting to wallet",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            }
        } else {
            setProvider(new WalletConnectProvider({ rpc: 'https://polygon-rpc.com' }))
            await provider.enable()
            setSigner(new WalletConnectProvider({ rpc: 'https://polygon-rpc.com' }).getSigner())
            setAccount(await new WalletConnectProvider({ rpc: 'https://polygon-rpc.com' }).getSigner().getAddress())
        }
    }, [provider, toast])

    useEffect(() => {
        if (!account) {
            if (window.ethereum) {
                window.ethereum.request({ method: 'eth_requestAccounts' }).then(async () => {
                    setProvider(new ethers.providers.Web3Provider(window.ethereum))
                    setSigner(new ethers.providers.Web3Provider(window.ethereum).getSigner())
                    setAccount(await new ethers.providers.Web3Provider(window.ethereum).getSigner().getAddress())
                })
            } else {
                connect()
            }
        }
        constants.changeNetwork(Number(0x89, 16))
        contract?.currentPhase().then((res) => {
            setCurrentPhase(res)
            contract?.phasePackPrice(res).then((e) => {
                setPrice(e.toString())
            })
            contract?.phaseMaxSupply(res).then((e) => {
                setMaxSupply(e)
            })
            contract?.phaseMaxPerWallet(res).then((e) => {
                setMaxPerWallet(e)
            })
        })
        contract?.totalSupply().then((res) => {
            setMinted(res)
        })
        
    }, [account, amount, connect, contract])
    const mint = async () => {
        if(!account){
            connect()
        }
        if (account) {
            try {
                const tx = await contract.mint(amount, {value: (price * amount).toString()})
                const rc = await tx.wait()
                toast({
                    title: "Success",
                    description: "Trait pack minted",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
            } catch (err) {
                toast({
                    title: "Error",
                    description: err.reason||err.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            }
        } else {
            toast({
                title: "Error",
                description: "Connect your wallet to mint a trait pack",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
    }




    return (

        <>
            <Container maxW={'container.xl'} my={10}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                    <GridItem colSpan={12}>
                        <HStack>
                            <Heading>Mint a GIGA PUNK Trait Pack</Heading>
                            <Spacer />
                            <Menu>
                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                    View On OpenSea
                                </MenuButton>
                                <MenuList>
                                    <Link href={'https://opensea.io/collection/giga-punks-1'}><MenuItem>Punks</MenuItem></Link>
                                    <Link href={'https://opensea.io/collection/giga-punks-traits'}><MenuItem>Traits</MenuItem></Link>
                                    <Link href={'https://opensea.io/collection/giga-punks-trait-packs'}><MenuItem>Trait Packs</MenuItem></Link>
                                </MenuList>
                            </Menu>
                            <Link href={'/unpack'}><Button>Unpack</Button></Link>
                            <Link href={'/build'}><Button>Build</Button></Link>
                            <Button onClick={toggleColorMode}>{colorMode == 'dark' ? <SunIcon /> : <MoonIcon />}</Button>
                            <Button onClick={connect} >Connect Wallet</Button>
                        </HStack>
                    </GridItem>
                    <GridItem colSpan={12}>
                        {account ? <Text>{'Connected as: ' + shorten(account)}</Text> : <Text>Connect your wallet to mint a trait pack</Text>}
                    </GridItem>
                    <GridItem colSpan={12}>
                        <Grid templateColumns={`repeat(auto-fit, minmax(300px, 1fr))`} gap={6}>
                            <GridItem colSpan={1} border='1px' p={3} rounded='2xl' textAlign={'center'} >
                                <Flex flexDir={'column'} >
                                <Text align={'center'}>current phase: {currentPhase.toString()}</Text>
                                <Text align={'center'}>max supply for current phase: {maxSupply.toString()}</Text>
                                <Text align={'center'}>price: {ethers.utils.formatEther(price.toString())} MATIC</Text>
                                <Text align={'center'}>phase mint progress: {minted.toString()}/{maxSupply.toString()}</Text>
                                <Slider my='5'  min={1} max={maxSupply.toString()} value={minted} defaultValue={0}>
                                    <SliderTrack h='5'>
                                        <SliderFilledTrack h='5' />
                                    </SliderTrack>
                                </Slider>
                                <Image rounded='2xl' src="https://gateway.ipfs.io/ipfs/bafybeic3w5jkpadyh2apnxegixrnwlajn3wlifwishrabrcerkkndv7ye4/pack.jpg" />
                                <Text align={'center'}>amount: {amount}</Text>
                                <Text align={'center'}>total price: {ethers.utils.formatEther((price * amount).toString())} MATIC</Text>
                                <Slider my='3' min={1} max={maxPerWallet.toString()} defaultValue={amount} onChange={(value) => setAmount(value)}>
                                    <SliderTrack>
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb />
                                </Slider>
                                <Button  onClick={mint} >Mint</Button>
                                </Flex>
                            </GridItem>
                            <GridItem colSpan={2} border='1px' p={3} rounded='2xl' textAlign={'center'} >
                                <Heading size={'lg'}>GIGA PUNKS</Heading>
                                <Text align={'center'} fontSize={'lg'} >
                                    GIGA PUNKS is a collection of 10,000 user-built punk NFTs on the Polygon network. Each punk has a unique set of 8 traits, which are randomly generated when unpacking your trait packs.<br/>
                                    The minting process is devided into 4 phases, with each phase having a different supply and price.<br/>

                                    The first phase has ended, and the currently ongoing phase is phase {currentPhase.toString()}. The it is limited to {maxSupply.toString()} punks, and the price is <span style={{color:'green'}}>{ethers.utils.formatEther(price.toString())} MATIC</span> per punk.                                     The following phases will be announced on our <Link color="teal" href="https://twitter.com/giga_punks" isExternal>Twitter</Link><br/>
                                    Or you can be nosy and look at the contract code:<br/> 
                                    <Link href="https://polygonscan.com/address/0xa59a64ae3f0d92ddfb48209e869c5d342e9a7281#code" color="teal" isExternal>Contract 1</Link><br/>
                                    <Link href="https://polygonscan.com/address/0x30d7815cd9fa35b67e37c5071aed95fac144f7f3#code" color="teal" isExternal>Contract 2</Link><br/>
                                    <Link href="https://polygonscan.com/address/0x020dAEAC6F88E68303a5Df5D07FdBcd94d18DC5B#code" color="teal" isExternal>Contract 3</Link><br/>

                                </Text>
                            </GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
            </Container>

        </>

    )

}
