import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Build from "./pages/build";


import Home from "./pages/home";
import Unpack from "./pages/unpack";



function App() {
  return (
    <>
      <Routes>
        <Route path="*" element={<Home/>}/>
        <Route path="build" element={<Build/>}/>
        <Route path="unpack" element={<Unpack/>}/>
      </Routes>
    </>
  );
}

export default App;
