import { Alert, background, Badge, Box, Button, Checkbox, Container, Flex, Grid, GridItem, Heading, HStack, Image, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Spacer, Spinner, Stack, Text, useColorMode, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as constants from "../constants"
import { useParams } from "react-router-dom"
import packAbi from "../abis/packs.json"
import unpackAbi from "../abis/unpack.json"

import { ChevronDownIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import WalletConnectProvider from "@walletconnect/web3-provider";
const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Unpack() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { colorMode, toggleColorMode } = useColorMode()
    const toast = useToast()
    const [account, setAccount] = useState(null)
    const [provider, setProvider] = useState(null)
    const [signer, setSigner] = useState(null)
    const [selectedPacks, setSelectedPacks] = useState([])
    const [nfts, setNfts] = useState([])
    const [approved, setApproved] = useState(false)

    const packs = useMemo(() => {
        if (provider) {
            return new ethers.Contract('0xA59a64AE3F0d92DDfB48209e869c5D342e9a7281', packAbi, signer)
        }
    }, [provider, signer])
    const unpack = useMemo(() => {
        if (provider) {
            return new ethers.Contract('0x30d7815cd9fA35B67e37C5071aed95FAc144F7F3', unpackAbi, signer)
        }
    }, [provider, signer])
    const connect = useCallback(async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: "wallet_requestPermissions",
                    params: [{
                        eth_accounts: {}
                    }]
                }).then(() => window.ethereum.request({
                    method: 'eth_requestAccounts'
                }))
                setProvider(new ethers.providers.Web3Provider(window.ethereum))
                setSigner(new ethers.providers.Web3Provider(window.ethereum).getSigner())
                setAccount(await new ethers.providers.Web3Provider(window.ethereum).getSigner().getAddress())
            } catch (err) {
                toast({
                    title: "Error",
                    description: "Error connecting to wallet",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            }
        } else {
            setProvider(new WalletConnectProvider({ rpc: 'https://polygon-rpc.com' }))
            await provider.enable()
            setSigner(new WalletConnectProvider({ rpc: 'https://polygon-rpc.com' }).getSigner())
            setAccount(await new WalletConnectProvider({ rpc: 'https://polygon-rpc.com' }).getSigner().getAddress())
        }
    }, [provider, toast])

    useEffect(() => {
        if (!account) {
            if (window.ethereum) {
                window.ethereum.request({ method: 'eth_requestAccounts' }).then(async () => {
                    setProvider(new ethers.providers.Web3Provider(window.ethereum))
                    setSigner(new ethers.providers.Web3Provider(window.ethereum).getSigner())
                    setAccount(await new ethers.providers.Web3Provider(window.ethereum).getSigner().getAddress())
                })
            } else {
                connect()
            }
        }else{
            packs?.isApprovedForAll(account, '0x30d7815cd9fA35B67e37C5071aed95FAc144F7F3').then((res) => {
                setApproved(res)
            })
        }
        constants.changeNetwork(Number(0x89, 16))

    }, [account, connect, packs])

    useMemo(() => {
        if (account) {
            const getNfts = async () => {
                const nfts = await packs?.tokensOfOwner(account).catch((e) => {
                    toast({
                        title: "Error",
                        description: e.reason||e.message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    })
                })
                setNfts(nfts)
            }
            getNfts()
        }
    }, [account, packs, toast])

    const unpackNfts = async () => {
        if(!account){
            connect()
        }
        if(!approved){
            const tx = await packs?.setApprovalForAll('0x30d7815cd9fA35B67e37C5071aed95FAc144F7F3', true).catch((e) => {
                toast({
                    title: "Error",
                    description: e.reason||e.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            })
            const rc = await tx.wait()
            if (rc.status == 1) {
                toast({
                    title: "Success",
                    description: "Approved",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
                setApproved(true)
            }
        }
        if (selectedPacks.length > 0) {
            const tx = await unpack?.unpack(selectedPacks).catch((e) => {
                toast({
                    title: "Error",
                    description: e.reason||e.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            })
            const rc = await tx.wait()
            if (rc.status == 1) {
                toast({
                    title: "Success",
                    description: "NFTs Unpacked",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
                setSelectedPacks([])
            }
        }
    }

                



    return (

        <>
            <Container maxW={'container.xl'} my={10}>
                <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                    <GridItem colSpan={12}>
                        <HStack>
                            <Heading>Unpack your Trait Packs</Heading>
                            <Spacer />
                            <Menu>
                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                    View On OpenSea
                                </MenuButton>
                                <MenuList>
                                    <Link href={'https://opensea.io/collection/giga-punks-1'}><MenuItem>Punks</MenuItem></Link>
                                    <Link href={'https://opensea.io/collection/giga-punks-traits'}><MenuItem>Traits</MenuItem></Link>
                                    <Link href={'https://opensea.io/collection/giga-punks-trait-packs'}><MenuItem>Trait Packs</MenuItem></Link>
                                </MenuList>
                            </Menu>
                            <Link href={'/'}><Button>Mint</Button></Link>
                            <Link href={'/build'}><Button>Build</Button></Link>
                            <Button onClick={toggleColorMode}>{colorMode == 'dark' ? <SunIcon /> : <MoonIcon />}</Button>
                            <Button onClick={connect} >Connect Wallet</Button>
                        </HStack>
                    </GridItem>
                    <GridItem colSpan={12}>
                        {account ? <Text>{'Connected as: ' + shorten(account)}</Text> : <Text>Connect your wallet to unpack your trait packs</Text>}
                    </GridItem>
                    <GridItem colSpan={12}>
                        <Grid templateColumns={`repeat(auto-fit, minmax(300px, 1fr))`} gap={6}>
                            <GridItem colSpan={2} border='1px' p={3} rounded='2xl' textAlign={'center'} >
                                <Grid templateColumns={`repeat(auto-fit, minmax(150px, 1fr))`} gap={6}>
                                    {nfts?.map((nft, i) => {
                                        return (
                                            <>
                                                <GridItem maxW={"200px"} colSpan={1} border='1px' p={3} rounded='2xl' textAlign={'center'} >
                                                    <Image rounded={'2xl'} src={'https://gateway.ipfs.io/ipfs/bafybeic3w5jkpadyh2apnxegixrnwlajn3wlifwishrabrcerkkndv7ye4/pack.jpg'} />
                                                    <Checkbox m='2' key={i} value={nft.toString()} onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedPacks([...selectedPacks, e.target.value])
                                                        } else {
                                                            setSelectedPacks(selectedPacks.filter((e) => e !== e.target.value))
                                                        }
                                                    }} />
                                                    <Text>#{nft.toString()}</Text>
                                                </GridItem>
                                            </>
                                        )
                                    })}
                                </Grid>
                            </GridItem>
                                <Button onClick={unpackNfts}>Unpack</Button>
                        </Grid>
                    </GridItem>
                </Grid>
            </Container>

        </>

    )

}