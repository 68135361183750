import { Alert, background, Badge, Box, Button, Checkbox, Container, Flex, Grid, GridItem, Heading, HStack, Image, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Spacer, Spinner, Stack, Text, useColorMode, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as constants from "../constants"
import { useParams } from "react-router-dom"
import punkAbi from "../abis/punks.json"
import traitAbi from "../abis/unpack.json"

import { ChevronDownIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import WalletConnectProvider from "@walletconnect/web3-provider";
const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Build() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { colorMode, toggleColorMode } = useColorMode()
    const toast = useToast()
    const [account, setAccount] = useState()
    const [provider, setProvider] = useState(null)
    const [signer, setSigner] = useState(null)
    const [selectedTraits, setSelectedTraits] = useState([])
    const [nfts, setNfts] = useState()
    const [nfts2, setNfts2] = useState([])
    const [punkNFTs, setPunkNFTs] = useState([])
    const [metadata, setMetadata] = useState([])
    const [metadata2, setMetadata2] = useState([])
    const [approved, setApproved] = useState(false)


    const punks = useMemo(() => {
        if (signer) {
            return new ethers.Contract('0x020dAEAC6F88E68303a5Df5D07FdBcd94d18DC5B', punkAbi, signer)
        }
    }, [ signer])
    const traits = useMemo(() => {
        if (signer) {
            return new ethers.Contract('0x30d7815cd9fA35B67e37C5071aed95FAc144F7F3', traitAbi, signer)
        }
    }, [ signer])
    const connect = useCallback(async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: "wallet_requestPermissions",
                    params: [{
                        eth_accounts: {}
                    }]
                }).then(() => window.ethereum.request({
                    method: 'eth_requestAccounts'
                }))
                setProvider(new ethers.providers.Web3Provider(window.ethereum))
                setSigner(new ethers.providers.Web3Provider(window.ethereum).getSigner())
                setAccount(await new ethers.providers.Web3Provider(window.ethereum).getSigner().getAddress())
            } catch (err) {
                toast({
                    title: "Error",
                    description: "Error connecting to wallet",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            }
        } else {
            setProvider(new WalletConnectProvider({ rpc: 'https://polygon-rpc.com' }))
            await provider.enable()
            setSigner(new WalletConnectProvider({ rpc: 'https://polygon-rpc.com' }).getSigner())
            setAccount(await new WalletConnectProvider({ rpc: 'https://polygon-rpc.com' }).getSigner().getAddress())
        }
    }, [provider, toast])

    useEffect(() => {
        if (!account) {
            if (window.ethereum) {
                window.ethereum.request({ method: 'eth_requestAccounts' }).then(async () => {
                    setProvider(new ethers.providers.Web3Provider(window.ethereum))
                    setSigner(new ethers.providers.Web3Provider(window.ethereum).getSigner())
                    setAccount(await new ethers.providers.Web3Provider(window.ethereum).getSigner().getAddress())
                })
            } else {
                connect()
            }
        } else if (account){
            traits?.isApprovedForAll(account, '0x020dAEAC6F88E68303a5Df5D07FdBcd94d18DC5B').then((res) => {
                setApproved(res)
            })
        }
        constants.changeNetwork(Number(0x89, 16))



    }, [account, connect, signer, traits])
    useMemo(() => {
        if (account) {
            traits?.tokensOfOwner(account).then((res) => {
                setNfts(res)
            })
        }
        if (nfts && !metadata.length) {
            nfts.forEach((nft) => {
                if (nft > 1400) {
                    fetch(`https://pub-3515c2e046a04fe18775ac44ba63a849.r2.dev/traits/metadata/${nft}.json`).then((res) => {
                        res.json().then((res) => {
                            metadata2[nft] = res
                        })
                    })
                } else if (nft <= 1400) {
                    fetch(`https://pub-3515c2e046a04fe18775ac44ba63a849.r2.dev/traits/metadata/${nft}.json`).then((res) => {
                        res.json().then((res) => {
                            metadata[nft] = res
                        })
                    })
                }
            })
        }
    }, [account, metadata, metadata2, nfts, traits])

    const build = async () => {
        if (!account) {
            connect()
        }
        if (!approved) {
            try {
                const tx = await traits?.setApprovalForAll('0x020dAEAC6F88E68303a5Df5D07FdBcd94d18DC5B', true)
                const rc = await tx.wait()
                console.log(rc)
                toast({
                    title: "Success",
                    description: "Approved",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
            }
            catch (e) {
                toast({
                    title: "Error",
                    description: e.reason || e.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            }
        }
        try {
            const tx = await punks?.build(selectedTraits)
            const rc = await tx.wait()
            console.log(rc)
            toast({
                title: "Success",
                description: "Your Punk has been built! If you enjoyed the process, please share it with your friends!",
                status: "success",
                duration: 16000,
                isClosable: true,
            })
        } catch (e) {
            toast({
                title: "Error",
                description: e.reason || e.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
    }

    if (metadata.length || metadata2.length) {
        return (

            <>
                <Container maxW={'container.xl'} my={10}>
                    <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                        <GridItem colSpan={12}>
                            <HStack>
                                <Heading>Build Your GIGA PUNK</Heading>
                                <Spacer />
                                <Menu>
                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                        View On OpenSea
                                    </MenuButton>
                                    <MenuList>
                                        <Link href={'https://opensea.io/collection/giga-punks-1'}><MenuItem>Punks</MenuItem></Link>
                                        <Link href={'https://opensea.io/collection/giga-punks-traits'}><MenuItem>Traits</MenuItem></Link>
                                        <Link href={'https://opensea.io/collection/giga-punks-trait-packs'}><MenuItem>Trait Packs</MenuItem></Link>
                                    </MenuList>
                                </Menu>
                                <Link href={'/unpack'}><Button>Unpack</Button></Link>
                                <Link href={'/'}><Button>Mint</Button></Link>
                                <Button onClick={toggleColorMode}>{colorMode == 'dark' ? <SunIcon /> : <MoonIcon />}</Button>
                                <Button onClick={connect} >Connect Wallet</Button>
                            </HStack>
                        </GridItem>
                        <GridItem colSpan={12}>
                            {account ? <Text>{'Connected as: ' + shorten(account)}</Text> : <Text>Connect your wallet to unpack your trait packs</Text>}
                        </GridItem>
                        <GridItem colSpan={12}>
                            <Grid templateColumns={`repeat(auto-fit, minmax(300px, 2fr))`} gap={6}>
                                <GridItem colSpan={2} border='1px' p={3} rounded='2xl' textAlign={'center'} >
                                    <Grid templateColumns={`repeat(auto-fit, minmax(150px, 1fr))`} gap={6}>
                                        <GridItem colSpan={1}>
                                            <Text>Background</Text>
                                            <Menu>
                                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                    {selectedTraits[0] ? metadata[selectedTraits[0]]?.attributes[0].value || metadata2[selectedTraits[0]]?.attributes[0].value : 'Select Background'}
                                                </MenuButton>
                                                <MenuList>
                                                <MenuItem isDisabled>Phase2 traits</MenuItem>
                                                    {metadata2?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'background') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[0] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                    <MenuItem isDisabled>Phase1 traits</MenuItem>
                                                    {metadata?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'background') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[0] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                </MenuList>
                                            </Menu>
                                        </GridItem>
                                        <GridItem colSpan={1}>
                                            <Text>Body</Text>
                                            <Menu>
                                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                    {selectedTraits[1] ? metadata[selectedTraits[1]]?.attributes[0].value || metadata2[selectedTraits[1]]?.attributes[0].value : 'Select Body'}
                                                </MenuButton>
                                                <MenuList>
                                                <MenuItem isDisabled>Phase2 traits</MenuItem>
                                                    {metadata2?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'body') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[1] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                    <MenuItem isDisabled>Phase1 traits</MenuItem>
                                                    {metadata?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'body') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[1] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                </MenuList>
                                            </Menu>
                                        </GridItem>
                                        <GridItem colSpan={1}>
                                            <Text>Clothes</Text>
                                            <Menu>
                                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                    {selectedTraits[2] ? metadata[selectedTraits[2]]?.attributes[0].value || metadata2[selectedTraits[2]]?.attributes[0].value : 'Select Clothes'}
                                                </MenuButton>
                                                <MenuList>
                                                <MenuItem isDisabled>Phase2 traits</MenuItem>
                                                    {metadata2?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'costume') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[2] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                    <MenuItem isDisabled>Phase1 traits</MenuItem>
                                                    {metadata?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'clothes') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[2] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                </MenuList>
                                            </Menu>
                                        </GridItem>
                                        <GridItem colSpan={1}>
                                            <Text>Neck Wear</Text>
                                            <Menu>
                                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                    {selectedTraits[3] ? metadata[selectedTraits[3]]?.attributes[0].value || metadata2[selectedTraits[3]]?.attributes[0].value : 'Select Neck Wear'}
                                                </MenuButton>
                                                <MenuList>
                                                <MenuItem isDisabled>Phase2 traits</MenuItem>
                                                    {metadata2?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'neck wear') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[3] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                    <MenuItem isDisabled>Phase1 traits</MenuItem>
                                                    {metadata?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'neck wear') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[3] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                </MenuList>
                                            </Menu>
                                        </GridItem>
                                        <GridItem colSpan={1}>
                                            <Text>Facial Hair</Text>
                                            <Menu>
                                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                    {selectedTraits[4] ? metadata[selectedTraits[4]]?.attributes[0].value || metadata2[selectedTraits[4]]?.attributes[0].value : 'Select Facial Hair'}
                                                </MenuButton>
                                                <MenuList>
                                                <MenuItem isDisabled>Phase2 traits</MenuItem>
                                                    {metadata2?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'beard') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[4] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                    <MenuItem isDisabled>Phase1 traits</MenuItem>
                                                    {metadata?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'facial hair') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[4] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                </MenuList>
                                            </Menu>
                                        </GridItem>
                                        <GridItem colSpan={1}>
                                            <Text>Mouth</Text>
                                            <Menu>
                                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                    {selectedTraits[5] ? metadata[selectedTraits[5]]?.attributes[0].value || metadata2[selectedTraits[5]]?.attributes[0].value : 'Select Mouth'}
                                                </MenuButton>
                                                <MenuList>
                                                <MenuItem isDisabled>Phase2 traits</MenuItem>
                                                    {metadata2?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'mouth') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[5] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                    <MenuItem isDisabled>Phase1 traits</MenuItem>
                                                    {metadata?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'mouth') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[5] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                </MenuList>
                                            </Menu>
                                        </GridItem>
                                        <GridItem colSpan={1}>
                                            <Text>Eyes</Text>
                                            <Menu>
                                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                    {selectedTraits[6] ? metadata[selectedTraits[6]]?.attributes[0].value || metadata2[selectedTraits[6]]?.attributes[0].value : 'Select Eyes'}
                                                </MenuButton>
                                                <MenuList>
                                                <MenuItem isDisabled>Phase2 traits</MenuItem>
                                                    {metadata2?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'eyes') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[6] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                    <MenuItem isDisabled>Phase1 traits</MenuItem>
                                                    {metadata?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'eyes') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[6] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                </MenuList>
                                            </Menu>
                                        </GridItem>
                                        <GridItem colSpan={1}>
                                            <Text>Head</Text>
                                            <Menu>
                                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                    {selectedTraits[7] ? metadata[selectedTraits[7]]?.attributes[0].value || metadata2[selectedTraits[7]]?.attributes[0].value : 'Select Head'}
                                                </MenuButton>
                                                <MenuList>
                                                <MenuItem isDisabled>Phase2 traits</MenuItem>
                                                    {metadata2?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'head') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[7] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                    <MenuItem isDisabled>Phase1 traits</MenuItem>
                                                    {metadata?.map((trait, index) => {
                                                        if (trait.attributes[0].trait_type == 'head') {
                                                            return <MenuItem onClick={() => {
                                                                let temp = selectedTraits
                                                                temp[7] = index
                                                                setSelectedTraits(temp)
                                                            }}><Image maxH={"30px"} src={trait.image} />{trait.attributes[0].value}</MenuItem>
                                                        }
                                                    })}
                                                </MenuList>
                                            </Menu>
                                        </GridItem>
                                    </Grid>
                                </GridItem>
                                {selectedTraits.some(e =>  e <= 1400 ) ? <GridItem colSpan={2} h='1200px' w='1200px' textAlign={'center'}>
                                    <Box rounded={'2xl'} overflow='hidden' bgImage={metadata[selectedTraits[0]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"50%"} h={"50%"} m={"auto"}>
                                        <Box bgImage={metadata[selectedTraits[1]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                            <Box bgImage={metadata[selectedTraits[2]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                                <Box bgImage={metadata[selectedTraits[3]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                                    <Box bgImage={metadata[selectedTraits[4]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                                        <Box bgImage={metadata[selectedTraits[5]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                                            <Box bgImage={metadata[selectedTraits[6]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                                                <Box bgImage={metadata[selectedTraits[7]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {selectedTraits.some(e => e > 1400) && selectedTraits.some(e => e <= 1400)? <Text color={'red'}>You can't mix and match phase1 and phase2 traits</Text> : null}
                                    <Button m='3' onClick={build}>Build your GIGA PUNK</Button>
                                </GridItem> :
                                    <GridItem colSpan={2} h='1200px' w='1200px' textAlign={'center'}>
                                        <Box rounded={'2xl'} overflow='hidden' bgImage={metadata2[selectedTraits[0]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"50%"} h={"50%"} m={"auto"}>
                                            <Box bgImage={metadata2[selectedTraits[1]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                                <Box bgImage={metadata2[selectedTraits[2]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                                    <Box bgImage={metadata2[selectedTraits[3]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                                        <Box bgImage={metadata2[selectedTraits[4]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                                            <Box bgImage={metadata2[selectedTraits[5]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                                                <Box bgImage={metadata2[selectedTraits[6]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                                                    <Box bgImage={metadata2[selectedTraits[7]]?.image.replace(' ', '%20').replace(' ', '%20').replace(' ', '%20')} bgSize={"cover"} bgPos={"center"} bgRepeat={"no-repeat"} w={"100%"} h={"100%"}>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {selectedTraits.some(e => e > 1400) && selectedTraits.some(e => e <= 1400)? <Text color={'red'}>You can't mix and match phase1 and phase2 traits</Text> : null}
                                        <Button m='3' onClick={build}>Build your GIGA PUNK</Button>
                                    </GridItem>
                                }


                            </Grid>
                        </GridItem>

                    </Grid>
                </Container>

            </>

        )

    } else {
        return <>
            <Container mt={'15%'}>
                <Heading>loading <Spinner /></Heading>
                <Text>If it keeps loading forever, it's because you don't own any traits. Go back to <Link color={'teal'} href="/">Mint</Link> or <Link color={'teal'} href="/unpack">Unpack</Link></Text>
            </Container>
        </>
    }
}